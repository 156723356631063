<template>
  <div>
    <span class="text-muted">Мессеж илгээх эсэх</span>
    <h5 class="mb-0">


      <b-badge v-if="customer.isSendSms" variant="success" class=" mt-2">Тийм</b-badge>
      <b-badge v-else variant="danger" class=" mt-2">Үгүй</b-badge>


      <b-button size="sm" variant="primary" class="pull-right" @click="showModal">засах</b-button>
    </h5>

    <b-modal ref="my-modal" title="Мессеж явах тохиргоо" hide-footer>
      <loading :active.sync="isLoading" :is-full-page="false" :color="this.$config.LOADER.color"
        :opacity="this.$config.LOADER.opacity" :background-color="this.$config.LOADER.backgroundColor" :z-index="10000">
      </loading>
      <b-form @submit.stop.prevent="save">
        <b-form-group label="Мессеж илгээх эсэх:" label-for="input-change-phone">
          <b-form-select id="input-change-phone" v-model="isSendSms" required placeholder="Сонгоно уу" :options="[
        { value: true, text: 'Тийм' },
        { value: false, text: 'Үгүй' },
      ]"></b-form-select>
        </b-form-group>
        <div class="pull-right">
          <b-button block type="submit" variant="primary">Хадгалах</b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'; // Import axios

export default {
  name: "Customer.isSendSms",
  props: ["customer", "callback"],
  data: function () {
    return {
      isLoading: false,
      first: true,
      isSendSms: false
    };
  },
  watch: {
    'customer.isSendSms': {
      handler(isSendSms) {
        this.isSendSms = isSendSms
      },
      deep: true
    }
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    save: function () {
      this.isLoading = true;

      // Prepare the data to be sent
      const data = {
        customerId: this.customer.id,
        v: this.isSendSms,
        email: this.$store.getters.email,
      };

      // Make the POST request using axios
      axios
        .post(
          this.$config.TEST_NUMUR_MS_ADMIN_URL + "set_is_send_sms", // API URL
          data, // Data to be sent
          {
            headers: {
              'Content-Type': 'application/json',  // Set Content-Type header
              
            }
          }
        )
        .then((response) => {
          this.isLoading = false;

          // Check the response from the API
          if (response.data.status === "success") {
            this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
            this.callback();
            this.hideModal();
          } else {
            this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          console.error("Error during the API call:", error);
          this.showToast("Алдаа", "Алдаа гарсан байна", "danger");
        });
    }
  }
};
</script>